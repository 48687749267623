export default class HandleBrowserProctoring {

    constructor({showFullscreenPrompt, submitAnswers, max_violations, setShowFullscreenPrompt,setShowOffcanvas,setOffcanvasMessage, warning_message, note_message,message_timer}) {
        this.properties = {
            count: 0,
            max_violations,
            submitAnswers,
            showFullscreenPrompt,
            setShowFullscreenPrompt,
            setShowOffcanvas,
            setOffcanvasMessage,
            warning_message,
            note_message,
            message_timer
        };
    }

    
    setProperty(key, value) {
        this.properties[key] = value;
    }

    
    getProperty(key) {
        return this.properties[key];
    }

    
    removeProperty(key) {
        delete this.properties[key];
    }

    
    getAllProperties() {
        return this.properties;
    }

    incrementCount(value = 1) {
        this.properties.count += value;
        console.log("total no of viaolations", this.properties.count)
    }

    TriggerMessages = (type) => {
        this.properties.setShowOffcanvas(false);
        const message = this.GenerateMessage(type);
        this.properties.setOffcanvasMessage(message);
        this.properties.setShowOffcanvas(true);
        // const message_timer = this.getProperty('message_timer');
        // setTimeout(() => {
        //     this.properties.setShowOffcanvas(false);
        // }, message_timer);
    }

    GenerateMessage = (type) => {
        const {setShowOffcanvas} = this.properties;
        var message;
        switch(type) {
            case 'warning': {
                message = this.getProperty('warning_message');
                return (
                    <div className='bg-white text-warning fw-medium border position-relative border-2 pe-4 border-warning p-3 d-flex align-items-center gap-2'>
                        <i class="bi bi-x-circle ms-auto cursor-pointer position-absolute" onClick={() => {setShowOffcanvas(false)}} style={{top: '3px', right: '3px'}}></i>
                        <i class="bi bi-exclamation-triangle"></i> 
                        <p className='m-0'>
                           {message()}
                        </p>
                    </div>
                )
            };
            case 'note': {
                message = this.getProperty('note_message');
                return (
                    <div className='bg-white text-warning fw-medium border position-relative border-2 pe-4 border-warning p-3 d-flex align-items-center gap-2'>
                        <i class="bi bi-x-circle ms-auto cursor-pointer position-absolute" onClick={() => {setShowOffcanvas(false)}} style={{top: '3px', right: '3px'}}></i>
                        <i class="bi bi-exclamation-triangle"></i> 
                        <p className='m-0'>
                        {message}
                        </p>
                    </div>
                )
            };
            default: {
                message = this.getProperty('warning_message')
                return (
                    <div className='bg-white text-warning fw-medium border position-relative border-2 pe-4 border-warning p-3 d-flex align-items-center gap-2'>
                        <i class="bi bi-x-circle ms-auto cursor-pointer position-absolute" onClick={() => {setShowOffcanvas(false)}} style={{top: '3px', right: '3px'}}></i>
                        <i class="bi bi-exclamation-triangle"></i> 
                        <p className='m-0'>
                           {message()}
                        </p>
                    </div>
                )
            }
        }
    }

    HandleViolation = () => {
        document.exitFullscreen()
            .then(() => {
                this.TriggerMessages('warning');
                this.incrementCount();
                this.properties.setShowFullscreenPrompt(true);
                this.properties.showFullscreenPrompt = true;
                if (this.properties.count > this.properties.max_violations) {
                    this.properties.submitAnswers();
                    return;
                }
                console.log("Exited fullscreen mode.");
            })
            .catch(e => {
                this.TriggerMessages('warning');
                this.incrementCount();
                this.properties.setShowFullscreenPrompt(true);
                this.properties.showFullscreenPrompt = true;
                if (this.properties.count > this.properties.max_violations) {
                    this.properties.submitAnswers();
                    return;
                }
                console.error("Error exiting fullscreen:");
            });
    }

    HandleVisibilityChange = async() => {
        if (document.visibilityState === 'hidden') {
            console.log("proctor triggered due to visibility change")
            if (document.fullscreenElement && !this.properties.showFullscreenPrompt) {
                this.HandleViolation();
            }
        }
    }

    HandleFullScreenChange = async() => {
        if (!document.fullscreenElement && !this.properties.showFullscreenPrompt) {
            console.log("proctor triggered due to fullscreen changes");
            this.HandleViolation();
        }
    }

    HandleBlur = async(e) => {
        e.preventDefault();
        console.log("proctor triggered due to blur", e.key? `on pressing ${e.key}`:"")
        if (document.fullscreenElement && !this.properties.showFullscreenPrompt) {
            this.HandleViolation();
        }
    }

    HandleKeyInputs = (e) => {
        if (e.key === 'Escape' || ((e.altKey) && e.key === 'Tab') || e.key == 'F11' || e.altKey || e.key === 'Tab' || e.key == 'F12') {
            e.preventDefault(); // Prevent the default action
            console.log("proctor triggered due to pressing", e.key);
            
        }
    }

    proctorBrowserViolations() {
        // Listen for visibility changes
        document.addEventListener('visibilitychange', this.HandleVisibilityChange);

        // Listen for fullscreen changes
        document.addEventListener('fullscreenchange', this.HandleFullScreenChange);

        // Listen for window blur (e.g., Alt + Tab)
        window.addEventListener('blur', this.HandleBlur);

        // Prevent Esc key and Alt+Tab
        window.addEventListener('keydown', this.HandleKeyInputs);
    }

    cleanup() {
        // to be called before exiting the exam inorder to destroy the event listeners 
        document.removeEventListener('visibilitychange', this.HandleVisibilityChange);
        document.removeEventListener('fullscreenchange', this.HandleFullScreenChange);
        window.removeEventListener('blur', this.HandleBlur);
        window.removeEventListener('keydown', this.HandleKeyInputs);
    }

}
