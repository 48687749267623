import './examEnvironment.css';
import { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
import Context from '../../user_details';
import Cookies from 'js-cookie'

import fullscreenimage from '../../assets/full-screen.png';

import Webcam from 'react-webcam';
import io from 'socket.io-client';

import CryptoJS from 'crypto-js';
import "./examEnvironment.scss";
import { tryInitializeWebcam, captureAndStream, setTimer, runTimer, checkExamAvailability } from './helpers';
import HandleBrowserProctoring from "./proctoringHandler";

import ErrorMessage from './errorMessage';
import NotAvailable from './notAvailable';
import CountDownTimer from './countDownTimer';

import HeaderStrip from './headerStrip';
import SectionsLoadingDiv from './sectionsLoaderDiv';
import QuestionsDiv from './questionsDiv';
import FullScreenPromptModal from './fullScreenPrompt';
import FinalConfirmationModal from './finalConfirmationModal';
import ThankYouModal from './thankYouModal';
import e from 'cors';

import { converttoLocalfromUTCWithOffset, convertToUTCWithOffsetFormatted, getCurrentUTCDateTime, getCurrentUTCDateTimeWithSeconds } from '../../helpers/timeHelpers';
import WebcamErrorMessage from './webcamError';

import {Offcanvas} from "react-bootstrap";


//const socket = io(process.env.REACT_APP_API_URL);
let socket;
let globalFinalAnswers = [];


export default function ExamEnvironment(props) {
    const [questionsLoader, setQuestionsLoader] = useState(true);
    const [sectionsLoader, setSectionsLoader] = useState(true);
    const [selectedSectionIndex, setSelectedSectionIndex] = useState(1);
    const [questions, setQuestions] = useState([]);
    const { exam_id, examinee, token } = useParams();
    const [Exam, setExam] = useState({});
    const [sectionValues, setSectionValues] = useState([]);
    const [ExamLoader, setExamLoader] = useState(true);
    const [selectedQuestion, setSelectedQuestion] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [sectionWiseAnswers, setSectionWiseAnswers] = useState([]);
    const [isExamAvailable, setExamAvailability] = useState(undefined);
    const [isSectionAvailable, setSectionAvailability] = useState(false);
    const [timerValue, setTimerValue] = useState([]);
    const [timeIntervals, setTimeIntervals] = useState([]);
    const [timeSpentPerQuestions, setTimeSpentPerQuestions] = useState([]);
    const context = useContext(Context);
    const user = useRef(context.user || {});
    const [isExamSubmitted, setIsExamSubmitted] = useState(false);
    const isExamSubmittedRef = useRef(isExamSubmitted);
    const [searchParams, setSearchParams] = useSearchParams();
    const hasFetched = useRef(false);
    const moveCountRef = useRef(0);
    const webcamRef = useRef(null);
    const webcamDivRef = useRef(null);
    const [webcamAccessError, setWebcamAccessError] = useState(false);
    const [examWillStartTime, setExamWillStartTime] = useState(null);
    const [showFullscreenPrompt, setShowFullscreenPrompt] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [answeredQuestionIds, setAnsweredQuestionIds] = useState([]);
    const [timer_warning, setTimerWarning] = useState(false);
    const [confirmSubmission, setConfirmSubmission] = useState(false);
    const [showConfirmSubmitModal, setShowConfirmSubmitModal] = useState(false);
    const [videoProctoring, setVideoProctoring] = useState(false);
    const [browserProctoring, setBrowserProctoring] = useState(false);
    const [timerInstance, setTimerInstance] = useState(undefined);
    const [prevTime, setPrevTime] = useState(0);
    const [prevQuestionIndex, setprevQuestionIndex] = useState(1);
    const [prevSectionIndex, setPrevSectionIndex] = useState(1);
    const [initiatedTime, setInitiation] = useState();
    const [additionalInfo, setAdditionalInfo] = useState("");
    const [remainingDays, setRemainingDays] = useState(0);
    const [remainingHours, setRemainingHours] = useState(0);
    const [remainingMinutes, setRemainingMinutes] = useState(0);
    const [remainingSeconds, setRemainingSeconds] = useState(0);
    // const [globalTimeSpentPerQuestions, setGlobalTimeSpentPerQuestions] = useState([]);
    const [examUTCStartTime, setExamUTCStartTime] = useState(null);
    const [currentUTCTime, setCurrentUTCTime] = useState(null);
    const [detectedInfo, setDetectedInfo] = useState('Caution: AI is monitoring you..');
    const [prevQuestionTimer, setPrevQuestionTimer] = useState(new Date());
    const timeSpentRef = useRef([]);
    const questionsRef = useRef([]);
    const sectionValuesRef = useRef([]);
    let previousPredictions = useRef([]);
    const [offcanvasMessage, setOffcanvasMessage] = useState("");
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [maxViolationsAllowed, setMaxViolations] = useState(3);
    const examRef = useRef({});
    const [browserProctor, setBrowserProctor] = useState(() => {
                                                    const proctor = new HandleBrowserProctoring({
                                                        showFullscreenPrompt,
                                                        max_violations: maxViolationsAllowed,
                                                        setShowFullscreenPrompt,
                                                        setShowOffcanvas,
                                                        setOffcanvasMessage,
                                                        submitAnswers: () => {submitAnswers()},
                                                        warning_message: () => {
                                                            return `Moving away from the exam is not allowed. ${proctor.getProperty('max_violations') - proctor.getProperty('count')} more move(s) and your exam will be submitted automatically.`
                                                        },
                                                        note_message: `Key not allowed`,
                                                       
                                                    });
                                                    return proctor;
                                                });
    useEffect(() => {
        timeSpentRef.current = timeSpentPerQuestions;
    },[timeSpentPerQuestions])

    //--------------functionality to prevent reload before confirmation---------- 
    window.onbeforeunload = (e) => {
        if (searchParams.size > 0) {
            Cookies.remove("nexus_student_login");
            Cookies.remove("nexus_student_id");
        }
        if (isExamSubmitted) {
            // --- functionality if needed if exam is submitted
        } else {
            e.preventDefault()
        }
    }
    // --------------------------------------------------------------------------
    useEffect(() => {
        if (Object.keys(props).length > 0) {
            let token = searchParams.get('token');
            // console.log("user details before", token)
            token = CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(token));
            // console.log("user details 1", token)
            token = CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(token));
            // console.log("user details 2", token)
            user.current = { _id: token };

        }
    }, []);

    useEffect(() => { 
        console.log("these are user details", user)
        if (!hasFetched.current && user.current && user.current._id) {
            getExamDetails();
            hasFetched.current = true;
        }
    }, [user]);

    useEffect(() => {
        console.log("these are the answeredQuestionIds", answeredQuestionIds)
    }, [answeredQuestionIds])


    useEffect(() => {
        if(browserProctoring) {
            browserProctor.proctorBrowserViolations();
            console.log(browserProctor, " this is browser proctor");
        } else {
            console.log('Browser Proctoring is Off for this exam pattern.');
        }

    }, [browserProctoring]);


    useEffect(() => {
        if(videoProctoring) {
            
            if (process.env.REACT_APP_BACKEND_URL) {
                socket = io(process.env.REACT_APP_BACKEND_URL, { transports: ['websocket'] });
                socket.on('connect', () => { console.log('Connected to WebSocket'); });
                socket.on('connect_error', (error) => { console.error('WebSocket connection error:', error); });
                socket.on('disconnect', () => { console.log('Disconnected from WebSocket'); });
            }
            tryInitializeWebcam(webcamDivRef, 0);
        } else {
            console.log('Video Proctoring is Off for this Exam Pattern.');
        }
        return () => {
            if (socket) {socket.disconnect();}
        };
    }, [videoProctoring]);

    useEffect(() => {

        console.log('I am in Proctoring useEffect (isExamSubmitted) : ', isExamSubmitted);

        //let previousPredictions = [];
        let isFirstFrameRef = {current:true};

        if(isExamSubmitted) isExamSubmittedRef.current = isExamSubmitted;

        const intervalId = setInterval(function(){
            console.log('calling captureAndStream', videoProctoring);
            if(videoProctoring && !isExamSubmittedRef.current) {
                captureAndStream(webcamRef, user.current, exam_id, socket, isExamSubmitted, isFirstFrameRef, previousPredictions, setDetectedInfo, detectedInfo);
            }
        }, 1000 * 10); // Capture image every 10 seconds
    
        return () => {
            clearInterval(intervalId);
        }

    }, [exam_id, isExamSubmitted, Exam, videoProctoring, isExamAvailable]);

    useEffect(() => {
        if(confirmSubmission == true) {
            submitAnswers();
        }
        setShowConfirmSubmitModal(0);
    }, [confirmSubmission]);


    useEffect(() => {
        if (!showConfirmSubmitModal) {
            setIsSubmitting(false);
        }
    }, [showConfirmSubmitModal])

    
    const isFullscreen = () => {
        return document.fullscreenElement ||
            document.mozFullScreenElement ||
            document.webkitFullscreenElement ||
            document.msFullscreenElement;
    };

    useEffect(() => {
        if (isExamAvailable != undefined && !isExamAvailable) {
            setExamLoader(false);
        }
    }, [isExamAvailable])

    
    useEffect(() => {
        if (isExamSubmitted) {
            document.getElementById("examSubmitSuccesstoggler").click();
        }
    }, [isExamSubmitted]);

    useEffect(() => {
        
        if (timerValue.length === 3 && timeIntervals.length < selectedSectionIndex) {
            
            runTimer(isExamSubmitted, timerValue, setTimerValue, submitAnswers, setAdditionalInfo, setTimeIntervals);
        }
        try {
            if (timeToMilliseconds(timerValue[0], timerValue[1], timerValue[2]) <= timeToMilliseconds(0, 10, 0)) {
                setTimerWarning(true);
            }
        } catch (e) { console.log(e)}

    }, [timerValue]);

    useEffect(() => {
        if (sectionWiseAnswers.length > 0) setExamLoader(false);
        globalFinalAnswers = sectionWiseAnswers;
    }, [sectionWiseAnswers]);

    useEffect(() => {
        if (Object.keys(Exam).length > 0) {
            checkExamAvailability(Exam, setTimerValue, setExamAvailability, setRemainingDays, setRemainingHours, setRemainingMinutes, setRemainingSeconds, setExamWillStartTime);
            const fetchInitialTimerDate = async() => {
                var examUTCStartTime = await convertToUTCWithOffsetFormatted(Exam.startDate, Exam.timeZoneOffset.offset);
                var currentUTCTime = await getCurrentUTCDateTime();
                setCurrentUTCTime(currentUTCTime);
                setExamUTCStartTime(examUTCStartTime);
            }
            const setInitialTimeDate = async() => {
                var time = await getCurrentUTCDateTimeWithSeconds();
                time = await converttoLocalfromUTCWithOffset(time, Exam.timeZoneOffset.offset) 
                setInitiation(time);
            }
            setInitialTimeDate();
            fetchInitialTimerDate();
        }
    }, [Exam]);

    useEffect(() => {
        if (initiatedTime) {
            console.log("initiated time", initiatedTime)
        }
    }, [initiatedTime])
    
    useEffect(() => {
        if (isExamAvailable && !isExamSubmitted) {
            checkSectionAvailability();
            setSectionValues(Exam.sections);
            sectionValuesRef.current = Exam.sections;
            setInitialAnswers();
        }
    }, [isExamAvailable]);

    useEffect(() => {
        if (sectionValues.length > 0) {
            setSectionsLoader(false);
            setQuestionsLoader(true);
            console.log('sectionValues : ', sectionValues)
            setQuestions([...sectionValues[selectedSectionIndex - 1].questions]);
            questionsRef.current = [...sectionValues[selectedSectionIndex - 1].questions];
        }
    }, [sectionValues]);

    useEffect(() => {
        console.log(questions, " these are the questions")
        if (questions.length > 0) {
            setSelectedQuestion(1);
            setQuestionsLoader(false);
        }
    }, [questions]);

    useEffect(() => {
        setSectionsLoader(true);
        setSectionValues([...sectionValues]);
        sectionValuesRef.current = Exam.sections;
        if (sectionValues.length > 0 && !Object.keys(Exam).includes("startDate")) clearAllIntervals();
    }, [selectedSectionIndex]);

    const handleWebcamError = (error) => {
        console.error("Webcam access was rejected:", error);
        setWebcamAccessError(true);  // Set error state to true to show the error message
    };

    const retryWebcamAccess = (e) => {
        e.preventDefault();
        setWebcamAccessError(false);  // Hide the error message
        navigator.mediaDevices.getUserMedia({ video: true })
        .then(() => {
            // Success: Initialize the webcam
            console.log('Webcam access granted');
            setWebcamAccessError(false);
            tryInitializeWebcam(webcamDivRef, 0);
        })
        .catch((error) => {
            console.error('Error accessing webcam:', error);
            setWebcamAccessError(true);  // Show error message if access fails
        });
    };

    const setInitialAnswers = async () => {
        // console.log("setting initial answers and timespends")
        let temporarySectionWiseAnswers = [], temporaryTimeSpentPerQuestion = [], temporaryAnsweredQuestionIds = [];
        await Promise.all(
            Exam.sections.map(async (section, sectionIndex) => {
                temporarySectionWiseAnswers.push({});
                temporaryAnsweredQuestionIds.push(new Set([]));
                // console.log("time sections", section)
                temporaryTimeSpentPerQuestion.push({ section_id: section.section_id, time_spent: {} });
                await Promise.all(
                    section.questions.map((question, questionIndex) => {
                        switch(Number(question.question_type)) {
                            case 1:
                            case 2: 
                            case 3: {
                                temporarySectionWiseAnswers[sectionIndex][question._id] = [];
                                temporaryTimeSpentPerQuestion[sectionIndex].time_spent[question._id] = 0;
                                break;
                            };
                            case 4: {
                                let currentQuestionAnswers = {};
                                for(const comprehensiveQuestion of question.comprehensive_questions) {
                                    currentQuestionAnswers[comprehensiveQuestion._id] = [];
                                }
                                temporarySectionWiseAnswers[sectionIndex][question._id] = currentQuestionAnswers;
                                temporaryTimeSpentPerQuestion[sectionIndex].time_spent[question._id] = 0;
                                break;
                            }
                        }
                    })
                )
                
            })
        ).then(() => {
            setSectionWiseAnswers([...temporarySectionWiseAnswers])
            setTimeSpentPerQuestions([...temporaryTimeSpentPerQuestion])
            setAnsweredQuestionIds([...temporaryAnsweredQuestionIds])
        })
        .catch((e) => {
            console.log(e)
            // alert("unable to initialize exam!!!")
        })
    }

    const timeToMilliseconds = (hours, minutes, seconds) => {
        // Calculate total milliseconds
        let totalMilliseconds = ((hours * 60 + minutes) * 60 + seconds) * 1000;
        return totalMilliseconds;
    }

    const submitAnswers = async () => {
        console.log('I am in submitAnswers (isExamSubmitted) : ', isExamSubmittedRef.current, isSubmitting);
        const attemptedViolations = browserProctor.getProperty('count');
        if(!isExamSubmittedRef.current && !isSubmitting) {
            if (attemptedViolations <= maxViolationsAllowed && !confirmSubmission && (timerValue[0] > 0 || timerValue[1] > 0 || timerValue[2] > 0)) {
                setShowConfirmSubmitModal(true);
            } else {
                try {
                    // clearInterval(timerInstance);
                    timeSpentRef.current[prevSectionIndex - 1].time_spent[questionsRef.current[prevQuestionIndex - 1]._id] += parseInt((new Date() - prevQuestionTimer) / 1000);
                    setIsSubmitting(true);
                    let finalAnswers = globalFinalAnswers;
                    // console.log(globalFinalAnswers," here are globalFinalAnswers")
                    // console.log(globalFinalAnswers," here are globalFinalAnswers")
                    await new Promise((res, rej) => {
                        if (finalAnswers.length === 0) {
                            res();
                        } else {
                            finalAnswers.forEach((sectionAnswers, index) => {
                                finalAnswers[index] = { section_id: sectionValuesRef.current[index].section_id, answers: sectionAnswers }
                                if (index === finalAnswers.length - 1) {
                                    res();
                                }
                            })
                        }
                    });
                    // const initiationTime = new Date(initiatedTime)
                    const initiationTime = new Date().toISOString();
                    const result = await axios.post(`${process.env.REACT_APP_API_URL}/exam/submit-exam-by-user`, {
                        client_id: user.current.firm_id,
                        answers: finalAnswers,
                        timeSpentPerQuestions: timeSpentRef.current,
                        user_id: user.current._id,
                        exam_id: examRef.current._id,
                        no_of_violations: attemptedViolations,
                        initiatedTime: initiationTime
                    });
                    if (result.status == 200) {
                        if(attemptedViolations > maxViolationsAllowed) {
                            setAdditionalInfo('Your exam has been automatically submitted because you moved out of the exam '+attemptedViolations+' times.')
                        }
                        
                        clearAllIntervals();  // Clear all intervals before setting exam as submitted
                        setIsExamSubmitted(true);
                        
                    }
                } catch (e) {
                    setQuestionsLoader(false);
                    console.error(e, "Answer Submission error");
                }
            }
        }
    }

    const getExamDetails = async () => {
        try {
            const examDetails = await axios.get(process.env.REACT_APP_API_URL + `/exam/read-conduct/${exam_id}/${user.current._id}`);
            console.log("this is exam api data",examDetails.data)
            setExam(examDetails.data);
            examRef.current = examDetails.data;
            setBrowserProctoring(examDetails.data.selectedExamPattern.browser_proctoring);
            setVideoProctoring(examDetails.data.selectedExamPattern.video_proctoring);
        } catch (e) {
            console.log(e);
            if (e.response && e.response.data && e.response.data.message) {
                setErrorMessage(e.response.data.message);
            }
        }
    }
    
    const changeQuestion = (questionNumber) => {
        setSelectedQuestion(questionNumber);
    }

    const clearAllIntervals = async () => {
        //if(!Object.keys(Exam).includes('startDate')){
        await new Promise((res, rej) => {
            if (timeIntervals.length == 0) res();
            timeIntervals.map((interval, index) => {
                clearInterval(interval);
                if (index == timeIntervals.length - 1) {
                    res();
                }
            })
        });
        setTimeIntervals([]);
        checkSectionAvailability();
        //}
    }

    const checkSectionAvailability = async () => {
        // clearInterval(timer);
        if (Object.keys(Exam).length > 0) {
            if (Object.keys(Exam).includes('startDate')) setSectionAvailability(true);
            else if (Object.keys(Exam.sections[selectedSectionIndex - 1]).includes('startDate')) {
                // clearInterval(timer);
                const current = new Date();
                let start = new Date(Exam.sections[selectedSectionIndex - 1].startDate);
                start.setMinutes(start.getMinutes() - 30);
                start.setHours(start.getHours() - 5);
                let end = new Date(Exam.sections[selectedSectionIndex - 1].endDate);
                end.setMinutes(end.getMinutes() - 30);
                end.setHours(end.getHours() - 5);
                const sectionAvailability = current >= start && current <= end;
                if (sectionAvailability && !Object.keys(Exam).includes("startExam")) {
                    setTimer(end);
                }
                setSectionAvailability(sectionAvailability);
            }
            else {
                setSectionAvailability(true);
            }
        }
    }

    const confirmSubmitAnswers = (value) => {
        setConfirmSubmission(value);
    }

    if(isExamSubmitted) {
        return (
            <>
                {/* ------------------success submit message modal---------------------- */}
                <button type="button" className="btn btn-primary d-none" id="examSubmitSuccesstoggler" data-bs-toggle="modal" data-bs-target="#examSubmitSuccessModal" />
                <ThankYouModal additionalInfo={additionalInfo}/>
            </>
        )
    } else if(webcamAccessError) {
        return (<div className=' main-exam-conduct mt-5'>
            <WebcamErrorMessage retryWebcamAccess={retryWebcamAccess}/>
        </div>)
    } else if (!ExamLoader && isExamAvailable && !isExamSubmitted && !webcamAccessError) {
        return (
            <div onContextMenu= {(e) => { e.preventDefault(); }} className='exam-room-main-container main-exam-conduct'>
                <Offcanvas show={showOffcanvas} style={{height: 'fit-content'}} placement='end'>
                    <Offcanvas.Body className='p-0'>
                        {offcanvasMessage}
                    </Offcanvas.Body>
                </Offcanvas>
                <div className='examHeader exam-room-header'>
                    <div className='container-fluid'>
                        {videoProctoring && <div className="webcam-container" ref={webcamDivRef}>
                            <Webcam audio={false} ref={webcamRef} screenshotFormat="image/png" style={{ width: '100%', height: '100%' }} videoConstraints={{ width: 1280, height: 720, facingMode: 'user' }} screenshotQuality={1.0} onUserMediaError={handleWebcamError} />
                            <div className='detectedInfo'>{detectedInfo}</div>
                        </div>}
                        <HeaderStrip Exam={Exam} isExamAvailable={isExamAvailable} timer_warning={timer_warning} timerValue={timerValue} isExamSubmitted={isExamSubmitted} submitAnswers={submitAnswers} isSubmitting={isSubmitting}/>
                    </div>
                </div>
                {/* -----------------Section Menu Container-------------------------------------------------- */}
                <div className='w-100 d-flex align-items-stretch' style={{ height: '93vh' }}>
                    {!sectionsLoader &&
                        <SectionsLoadingDiv setQuestionsLoader={setQuestionsLoader} sectionValues={sectionValues} setSelectedSectionIndex={setSelectedSectionIndex} selectedSectionIndex={selectedSectionIndex} isSectionAvailable={isSectionAvailable} answeredQuestionIds={answeredQuestionIds} selectedQuestion={selectedQuestion} changeQuestion={changeQuestion} />
                    }
                    {/* ----------------Questions Display Container------------------- */}
                    {!questionsLoader && isSectionAvailable && questions &&
                        <QuestionsDiv timeSpentPerQuestions={timeSpentPerQuestions} setTimeSpentPerQuestions={setTimeSpentPerQuestions} prevQuestionTimer={prevQuestionTimer} setPrevQuestionTimer={setPrevQuestionTimer} setTimerInstance={setTimerInstance} sectionValues={sectionValues} setQuestionsLoader={setQuestionsLoader} setSelectedSectionIndex={setSelectedSectionIndex} prevQuestionIndex={prevQuestionIndex} timerInstance={timerInstance} prevTime={prevTime} Exam={Exam} isExamAvailable={isExamAvailable} isExamSubmitted={isExamSubmitted} timerValue={timerValue} submitAnswers={submitAnswers} questionsLoader={questionsLoader} questions={questions} sectionWiseAnswers={sectionWiseAnswers} setSectionWiseAnswers={setSectionWiseAnswers} selectedSectionIndex={selectedSectionIndex} selectedQuestion={selectedQuestion} setAnsweredQuestionIds={setAnsweredQuestionIds} answeredQuestionIds={answeredQuestionIds} prevSectionIndex={prevSectionIndex} setPrevSectionIndex={setPrevSectionIndex} setprevQuestionIndex={setprevQuestionIndex} setSelectedQuestion={setSelectedQuestion} setPrevTime={setPrevTime}  user={user} />
                    }
                    {questionsLoader && <div className='d-flex flex-grow-1 h-100 w-100 bg-white'></div>}
                    {!questionsLoader && !isSectionAvailable &&
                        <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                            This section is currently unavailable and will unlock on {Exam.sections[selectedSectionIndex - 1].startDate}
                        </div>
                    }
                                        
                </div>
                {showFullscreenPrompt ? (
                    <FullScreenPromptModal proctorDetails={{browserProctor, browserProctoring}} showFullscreenPrompt={showFullscreenPrompt} fullscreenimage={fullscreenimage} setShowFullscreenPrompt={setShowFullscreenPrompt}/>
                ) :
                    showConfirmSubmitModal && <FinalConfirmationModal showConfirmSubmitModal={showConfirmSubmitModal} setShowConfirmSubmitModal={setShowConfirmSubmitModal} confirmSubmitAnswers={setConfirmSubmission} />
                }

            </div>
        )
    
    } else {
        if (!isExamAvailable) {
            if (!ExamLoader) {
                return (
                    <div className='h-100 w-100'>
                        <div className="no-exams exam-countdown-timer">
                            <div className="no-exams-text-container">
                                {new Date(examUTCStartTime) > new Date(currentUTCTime) ? (
                                    <CountDownTimer remainingDays={remainingDays} remainingHours={remainingHours} remainingMinutes={remainingMinutes} remainingSeconds={remainingSeconds}/>
                                ) : (
                                    <NotAvailable fullscreenimage={fullscreenimage}/>
                                )}
                            </div>
                        </div>
                    </div>
                )
            } else return (
                <ErrorMessage errorMessage={errorMessage} fullscreenimage={fullscreenimage}/>
            )
        }
    }
}